<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2><i class="fas fa-users"></i>&nbsp;&nbsp;Leads Management</h2>
            </div>
            <!--<div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <p>You can manage your Leads setting on here, click the link below if you need more information</p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-2">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div>-->
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>
        <div class="row" v-if="IsAdmin">
             <div class="col-sm-12 col-md-12 col-lg-12 form-group has-label pull-left">
                            <label id="selClientCampaign">Select Client Campaign Name</label>
                                <el-select
                                    class="select-primary"
                                    size="large"
                                    placeholder="Select Client Campaign Name"
                                    filterable
                                    default-first-option
                                    v-model="selects.companySelected"
                                    @change="onCompanyChange(selects.companySelected);"
                                >
                                
                                    <el-option
                                        v-for="option in selects.companyList"
                                        class="select-primary"
                                        :value="option.id"
                                        :label="(option.leadspeek_api_id !='')?option.campaign_name + ' #' + option.leadspeek_api_id:option.campaign_name"
                                        :key="option.id"
                                    >
                                    </el-option>
                                </el-select>
                        </div>
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>
        <div class="row" id="settingleadsarea">
            <div class="col-sm-4 col-md-4 col-lg-4" v-if="false">
                <card class="cardwithminimum">
                    <template slot="header">
                        <h5 class="card-category text-center">&nbsp;</h5>
                        <h3 class="card-title text-center">Leads Report Type</h3>
                    </template>

                    <div class="has-label">
                              <!--<base-radio name="CSV" v-model="radios.reportType">CSV Attachment</base-radio>-->
                              <!--<base-radio name="Excel" v-model="radios.reportType">Microsoft Excel Attachment</base-radio>-->
                              <base-radio name="GoogleSheet" v-model="radios.reportType" :disabled="GoogleConnectFalse">Google Sheet Online</base-radio>
                    </div>

                    <template slot="footer">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 text-right" v-if="this.$global.menuLeadsPeek_update">
                            <base-button type="info" round icon @click="show_helpguide('reporttype')">
                            <i class="fas fa-question"></i>
                            </base-button>
                            
                            
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 text-left" v-if="this.$global.menuLeadsPeek_update">
                            <base-button class="btn-green" round icon @click="save_lead_setting('reporttype')">
                            <i class="fas fa-save"></i>
                            </base-button>
                        </div>
                    </div>
                    </template>
                </card>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4" v-if="false">
                <card class="cardwithminimum">
                    <template slot="header">
                        <h5 class="card-category text-center">&nbsp;</h5>
                        <h3 class="card-title text-center">Report Frequency</h3>
                    </template>

                    <div class="has-label">
                              
                                        <el-select
                                            class="select-primary select-fullwidth"
                                            size="large"
                                            placeholder="Select Role"
                                            v-model="selects.frequencySelected"
                                           
                                        >
                                        
                                            <el-option
                                                v-for="option in selects.frequencyOptions"
                                                class="select-primary"
                                                :value="option.id"
                                                :label="option.frequency_name"
                                                :key="option.id"
                                            >
                                            </el-option>
                                        </el-select>
                    </div>

                    <template slot="footer">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 text-right" v-if="this.$global.menuLeadsPeek_update">
                            <base-button type="info" round icon @click="show_helpguide('reportfrequency')">
                            <i class="fas fa-question"></i>
                            </base-button>
                            
                            
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 text-left" v-if="this.$global.menuLeadsPeek_update">
                            <base-button class="btn-green" round icon @click="save_lead_setting('reportfrequency')">
                            <i class="fas fa-save"></i>
                            </base-button>
                        </div>
                    </div>
                    </template>
                </card>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4" v-if="false">
                <card class="cardwithminimum">
                    <template slot="header">
                        <h5 class="card-category text-center">&nbsp;</h5>
                        <h3 class="card-title text-center">Reports Sent To</h3>
                    </template>

                    <div class="has-label">
                             <base-input >
                                <textarea
                                class="form-control"
                                v-model="reportSentTo"
                                placeholder="Put client email(s) here, separate by new line" rows="50">
                                </textarea>
                            </base-input>
                    </div>

                    <template slot="footer">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 text-right" v-if="this.$global.menuLeadsPeek_update">
                            <base-button type="info" round icon @click="show_helpguide('reportsentto')">
                            <i class="fas fa-question"></i>
                            </base-button>
                            
                            
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 text-left" v-if="this.$global.menuLeadsPeek_update">
                            <base-button class="btn-green" round icon @click="save_lead_setting('reportsentto')">
                            <i class="fas fa-save"></i>
                            </base-button>
                        </div>
                    </div>
                    </template>
                </card>
            </div>
           
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <card>
                        <template slot="header">
                            <h5 class="card-category text-center">&nbsp;</h5>
                            <h3 class="card-title text-center">Whitelist Your Current Database</h3>
                        </template>
                            <div>
                                <!--UPLOAD-->
                                    <form enctype="multipart/form-data">
                                        <!--<h5>Drag & Drop your suppression List (file type should be .csv). Download <a href="#">example file</a></h5>-->
                                        <div class="dropbox">
                                        <input type="file" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                                            accept=".csv" class="input-file">
                                            <p v-if="isInitial">
                                            Drag your file here to upload<br/>or click to browse<br/>
                                            <base-button type="info" round icon  @click="show_helpguide('suppression')">
                                                <i class="fas fa-question"></i>
                                            </base-button>
                                            </p>
                                            <p v-if="isSaving">
                                                Please Wait, your file being upload ...
                                            </p>
                                            <p v-if="isSuccess">
                                                Your Suppression file has been Submitted, Thank you!
                                            </p>
                                            <p v-if="isFailed">
                                                Whoops that did not work, please check your file for errors and try again
                                            </p>
                                        </div>
                                    </form>
                            </div>
                        <template slot="footer">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                    <!--<base-button type="info" round icon  @click="show_helpguide('suppression')">
                                    <i class="fas fa-question"></i>
                                    </base-button>
                                    -->
                                    
                                </div>
                            </div>
                        </template>
                    </card>
                </div>

                 

                <div class="col-sm-12 col-md-12 col-lg-12" >
                    <card>
                        <template slot="header">
                            <h5 class="card-category text-center">&nbsp;</h5>
                            <h3 class="card-title text-center">Local Information</h3>
                        </template>
                        <div class="pt-3 pb-3">&nbsp;</div>
                        <div class="row">
                            <div class="col-sm-4 col-md-4 col-lg-4 text-left" v-if="false">
                                <div class="text-center"><label>Required Fields</label></div>
                                <base-checkbox value="FirstName,LastName" v-model="ClientRequireFields.fname_lname" >Emails and Names</base-checkbox>
                                <base-checkbox value="" v-model="ClientRequireFields.none" >Deliver all leads - even incomplete records</base-checkbox>
                
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4 text-center">
                                &nbsp;
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4 text-center">
                                &nbsp;
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                    <base-checkbox name="enabledphonenumber" v-model="checkboxes.phoneenabled"  inline>Enable Phone Number</base-checkbox>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                    <base-checkbox name="enabledhomeaddress" v-model="checkboxes.homeaddressenabled" inline>Enable Home Address</base-checkbox>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                    <base-checkbox name="requireemailaddress" v-model="checkboxes.requireemailaddress" inline>Require Email Address</base-checkbox>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                                <div><label><strong>Re-identification lead time :</strong></label></div>
                                <base-radio name="never" v-model="Reidentificationtime" class="d-inline">Never</base-radio>
                                <base-radio name="1 week" v-model="Reidentificationtime"  class="d-inline pl-2">1 Week</base-radio>
                                <base-radio name="1 month" v-model="Reidentificationtime"  class="d-inline pl-2">1 Month</base-radio>
                                <base-radio name="3 months" v-model="Reidentificationtime"  class="d-inline pl-2">3 Months</base-radio>
                                <base-radio name="6 months" v-model="Reidentificationtime"  class="d-inline pl-2">6 Months</base-radio>
                                <base-radio name="1 year" v-model="Reidentificationtime"  class="d-inline pl-2">1 Year</base-radio>
                                <br/><small>* Select the frequency that leads will be re-identified in this campaign.</small>
                            </div>

                        </div>
                        <template slot="footer">
                            <div class="row pull-right">
                                <div class="col-sm-6 col-md-6 col-lg-6 text-right" v-if="this.$global.menuLeadsPeek_update">
                                    <base-button type="info" round icon @click="show_helpguide('locatorinfo')">
                                    <i class="fas fa-question"></i>
                                    </base-button>
                                    
                                    
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6 text-left" v-if="this.$global.menuLeadsPeek_update">
                                    <base-button class="btn-green" round icon @click="save_lead_localinfo()">
                                    <i class="fas fa-save"></i>
                                    </base-button>
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
        

        </div>

        <!-- Modal Video Guide -->
            <modal :show.sync="modals.helpguide" headerClasses="justify-content-center" modalContentClasses="customhead">
                <h4 slot="header" class="title title-up" v-html="modals.helpguideTitle">Lead Report Type</h4>
                <p class="text-center" v-html="modals.helpguideTxt">
               You can select the type of report you want to receive. If you choose the CSV, you will be sent an attachment in an email. If you choose a Google Sheet, you will be given access to your private Google Sheet and leads will be added automatically according to the Report Frequency. You may select notification options in the Google Sheet settings if you wish.
                </p>
            
                <template slot="footer">
                <div class="container text-center pb-4">
                    <base-button  @click.native="modals.helpguide = false">Close</base-button>
                </div>
                </template>
            </modal>
        <!-- Modal Video Guide -->

    </div>
</template>

<script>
import { Modal,BaseRadio } from 'src/components';
import { Table, TableColumn, Select, Option } from 'element-ui';
import axios from 'axios';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
axios.defaults.baseURL = process.env.VUE_APP_DATASERVER_URL + '/api';
var CHECK_GROUPCOMPANY;

export default {
    components:{
        Modal,
        BaseRadio,
        [Option.name]: Option,
        [Select.name]: Select,
    },

    data() {
        return {
            /** FOR SUPRESSION UPLOAD FILE */
            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,
            uploadFieldName: 'suppressionfile',
            /** FOR SUPRESSION UPLOAD FILE */
            GoogleConnectFalse: false,
            GoogleConnectTrue: false,
            ClientData: [],
            ClientActiveID: '',
            IsAdmin: false,
            TryseraMethod: true,

            LeadspeekID: '',
            reportSentTo:'',
            Reidentificationtime : 'never',

            modals: {
                helpguide: false,
                helpguideTitle:'',
                helpguideTxt: '',
            },

            checkboxes: {
                phoneenabled:false,
                homeaddressenabled:false,
                requireemailaddress:true,
            },

            radios: {
                reportType: 'GoogleSheet',
            },

            ClientRequireFields: {
                fname_lname: false,
                none: false,
            },

            selects: {
                frequencySelected: '24_hours',
                frequencyOptions: [
                    { id: '5_minutes', frequency_name: 'Every 5 minutes' },
                    { id: '15_minutes', frequency_name: 'Every 15 minutes' },
                    { id: '30_minutes', frequency_name: 'Every 30 minutes' },
                    { id: '1_hours', frequency_name: 'Every 1 hours' },
                    { id: '6_hours', frequency_name: 'Every 6 hours' },
                    { id: '24_hours', frequency_name: 'Every 24 hours' },
                ],

                companySelected: '',
                companyList: [],

            },
        }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
    },
    methods: {
        /** FOR SETTING LOCAL REQUIRED */
        save_lead_localinfo() {
            var _locatorrequire = "";
            if (this.ClientRequireFields.fname_lname) {
                _locatorrequire = _locatorrequire + "FirstName,LastName,";
            }
            _locatorrequire = _locatorrequire.replace(/,\s*$/, "");

            this.$store.dispatch('UpdateLeadsPeekClientLocal', {
                companyID: this.companyID,
                leadspeekID: this.LeadspeekID,
                //locatorzip: this.ClientLocatorZip,
                //locatordesc: this.ClientLocatorDesc,   
                //locatorkeyword: this.ClientLocatorKeyword,
                //locatorstate: this.ClientLocatorState,
                locatorrequire: _locatorrequire,
                //locatorcity: this.ClientLocatorCity,
                //sificampaign: this.SifiCampaign,
                //sifiorganizationid: this.SifiOrganizationID,
                phoneenabled: this.checkboxes.phoneenabled,
                homeaddressenabled: this.checkboxes.homeaddressenabled,
                requireemailaddress: this.checkboxes.requireemailaddress,
                reidentificationtype: this.Reidentificationtime,
            }).then(response => {
                //console.log(response[0]);  
                this.$notify({
                    type: 'success',
                    message: 'Setting has been saved.',
                    icon: 'tim-icons icon-bell-55'
                });  
            },error => {
                        
            });

        },
        /** FOR SETTNG LOCAL REQUIRED */

        /** FOR UPLOAD FILE */
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
        },
        save(formData) {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;

           //console.log(formData);

           const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin' : '*',
                }
           };
           var uploadurl = '/leadspeek/suppressionlist/upload';

           if (this.TryseraMethod == true) {
            uploadurl = '/leadspeek/suppressionlisttrysera/upload';
           }
           
           axios.post(uploadurl,formData,config)
            .then(response => {
                //console.log(response.data);
                if(response.data.result == 'success') {
                    this.currentStatus = STATUS_SUCCESS;
                    setTimeout(() => {
                        this.reset();
                    }, 2000);
                }else{
                    this.currentStatus = STATUS_FAILED;
                    setTimeout(() => {
                        this.reset();
                    }, 2000);
                }
            })
            .catch(error => {
                //console.log(error);
                this.currentStatus = STATUS_FAILED;
                setTimeout(() => {
                    this.reset();
                }, 2000);
                //reject(error)
            })
            /*upload(formData)
            .then(x => {
                this.uploadedFiles = [].concat(x);
                this.currentStatus = STATUS_SUCCESS;
            })
            .catch(err => {
                this.uploadError = err.response;
                this.currentStatus = STATUS_FAILED;
            });
            */
        },
        filesChange(fieldName, fileList) {
            // handle file changes
            const formData = new FormData();

            if (!fileList.length) return;

            // append the files to FormData
            Array
            .from(Array(fileList.length).keys())
            .map(x => {
                formData.append(fieldName, fileList[x], fileList[x].name);
            });
            
            formData.append("leadspeekID",this.LeadspeekID);
            
            // save it
            this.save(formData);
        },
        /** FOR POP UP GUIDE */
        show_helpguide(helptype) {
            if (helptype == 'reporttype') {
                this.modals.helpguideTitle = "Lead Report Type"
                this.modals.helpguideTxt = "You can select the type of report you want to receive. If you choose the CSV, you will be sent an attachment in an email. If you choose a Google Sheet, you will be given access to your private Google Sheet and leads will be added automatically according to the Report Frequency. You may select notification options in the Google Sheet settings if you wish."
            }else if (helptype == 'reportfrequency') {
                this.modals.helpguideTitle = "Report Frequency"
                this.modals.helpguideTxt = "We will send you a report if there are new leads according to the frequency you select here. If you have chosen a Google Sheet and want email notifications, please see this help article to enable notifications from Google. <a href='https://support.google.com/docs/answer/91588' target='_blank'>https://support.google.com/docs/answer/91588</a>"
            }else if (helptype == 'reportsentto') {
                this.modals.helpguideTitle = "Reports Sent To"
                this.modals.helpguideTxt = "We will send notifications to the email addresses you specify. Please put one email address per line."
            }else if (helptype == 'suppression') {
                this.modals.helpguideTitle = "Whitelist Your Current Database"
                this.modals.helpguideTxt = "We do not want to charge you for anyone who is currently in your database. You can Whitelist them by providing an encrypted list of email addresses, or by uploading a list of email addresses and we will encrypt them for you. Do not include any other information in the file aside from the email address. <a href='/samplefile/suppressionlist.csv' target='_blank'>Click here</a> to download a Sample File"
            }

            this.modals.helpguide = true;
        },
        /** FOR POP UP GUIDE */
        /** FOR UPLOAD FILE */
        save_lead_setting(fieldupdate) {
            var _valuefield;
            if (fieldupdate == 'reporttype') {
                _valuefield = this.radios.reportType;
            }else if(fieldupdate == 'reportfrequency') {
                _valuefield = this.selects.frequencySelected;
            }else if(fieldupdate == 'reportsentto') {
                _valuefield = this.reportSentTo;
            }
            
            this.$store.dispatch('UpdateLeadsPeekClient', {
                companyID: this.companyID,
                leadspeekID: this.LeadspeekID,
                fieldupdate: fieldupdate,
                valuefield: _valuefield,   
                userID: '',
                reportType: '',
                reportSentTo: '',
                adminNotifyTo: '',
                leadsAmountNotification: '',     
            }).then(response => {
                //console.log(response[0]);  
                this.$notify({
                    type: 'success',
                    message: 'Setting has been saved.',
                    icon: 'tim-icons icon-bell-55'
                });  
            },error => {
                        
            });
        },
        checkGoogleConnect() {
            this.$store.dispatch('checkGoogleConnectSheet', {
                companyID: this.companyID,
            }).then(response => {
                //console.log(response.googleSpreadsheetConnected);
                if (response.googleSpreadsheetConnected) {
                    this.GoogleConnectTrue = true;
                    this.GoogleConnectFalse = false;
                }else{
                    this.GoogleConnectTrue = false;
                    this.GoogleConnectFalse = true;
                }
            },error => {
                
            });
        },
        onCompanyChange(_companySelected) {
            this.ClientActiveID = _companySelected;
            this.GetClientList();
        },
        GetClientList() {
            var _groupCompanyID = '';
            if ((localStorage.getItem('companyGroupSelected') != null && localStorage.getItem('companyGroupSelected') != '')){
                _groupCompanyID = localStorage.getItem('companyGroupSelected');
            }
            /** GET CLIENT LIST */
            this.$store.dispatch('GetLeadsPeekClientList', {
                companyID: this.companyID,
                clientID: this.ClientActiveID,
                leadspeekType: 'local',
                groupCompanyID: _groupCompanyID,
            }).then(response => {
                //console.log(response.length);
                //console.log(response);
                if (this.ClientActiveID == '') {
                    this.selects.companyList = response;
                    //this.selects.companySelected = response[0].id;
                    //this.ClientActiveID = response[0].id;
                    var defaultCompany = 
                        {
                            'id':'',
                            'campaign_name':'Select Campaign',
                            'leadspeek_api_id' :'',
                        };
                    this.selects.companyList.unshift(defaultCompany);
                    this.selects.companySelected = '';
                    this.ClientActiveID = '';

                    $('#selClientCampaign').addClass('selectcampaign');
                    $('#settingleadsarea').addClass('disabled-area');
                }else{
                    $('#selClientCampaign').removeClass('selectcampaign');
                    $('#settingleadsarea').removeClass('disabled-area');
                }

                if (this.ClientActiveID != '') {
                    this.ClientData = response[0];
                    this.radios.reportType = this.ClientData.report_type;
                    this.reportSentTo = this.ClientData.report_sent_to;
                    this.selects.frequencySelected = this.ClientData.report_frequency_id;
                    this.LeadspeekID = this.ClientData.id;

                    this.ClientRequireFields.fname_lname = false;
                    this.ClientRequireFields.none = true;

                     var _requiredFields = this.ClientData.leadspeek_locator_require;
                    if (this.ClientData.phoneenabled == 'T') {
                        this.checkboxes.phoneenabled = true;
                    }else{
                        this.checkboxes.phoneenabled = false;
                    }

                    if (this.ClientData.homeaddressenabled == 'T') {
                        this.checkboxes.homeaddressenabled = true;
                    }else{
                        this.checkboxes.homeaddressenabled = false;
                    }

                    if (this.ClientData.require_email == 'T') {
                        this.checkboxes.requireemailaddress = true;
                    }else{
                        this.checkboxes.requireemailaddress = false;
                    }

                    this.Reidentificationtime = this.ClientData.reidentification_type;
                    if (this.ClientData.trysera == 'T') {
                        this.TryseraMethod = true;
                    }else{
                        this.TryseraMethod = false;
                    }
                    /*if (_requiredFields != null) {
                        if (_requiredFields == "FirstName,LastName") {
                            this.ClientRequireFields.fname_lname = true;
                        }else  if (_requiredFields == "") {
                            this.ClientRequireFields.none = true;
                        }
                    }*/

                }
                //console.log(this.LeadspeekID);
            },error => {
                
            });
            /** GET CLIENT LIST */
        },

    },

    mounted() {
        const userData = this.$store.getters.userData;
        this.companyID = userData.company_id;
        this.ClientActiveID = '';
        //console.log(userData);
        if(userData.user_type == "client") {
            //this.ClientActiveID = userData.id;
            localStorage.setItem('companyGroupSelected',userData.id);
            this.companyID = userData.company_parent;
            this.IsAdmin = true;
        }else{
            this.IsAdmin = true;
        }
        //this.GetClientList();
        CHECK_GROUPCOMPANY = setInterval(() => {
            if ((localStorage.getItem('companyGroupSelected') != null)){
                clearInterval(CHECK_GROUPCOMPANY);
                this.GetClientList();
            }
        }, 1000);
        this.checkGoogleConnect();
        this.reset();
    }
  
}
</script>

<style>
.select-fullwidth {
    width: 100%;
}

.cardwithminimum {
    min-height: 275px;
}

.dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    /* background: #27293d; */
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
   /* background: #27293d;  when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
  
  .disabled-area {
    pointer-events:none;
    opacity: 0.3;
  }

  .selectcampaign {
    font-weight:bold;
    color:white;
  }
</style>